import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import { H3, Text, PageContainer, ContentContainer } from '@system'
import font from '@helpers/font'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%',
    background:
      'linear-gradient(106.52deg, #1858A8 -3.9%, rgba(24, 88, 168, 0) 52.24%), #182F4E',
    justifyContent: 'center',
  },
  topImage: {
    backgroundImage: (props) =>
      props.backgroundImage &&
      props.backgroundImage.length > 0 &&
      `url(${props.backgroundImage[0].image})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    height: '100%',
    width: '100%',
    maxHeight: '404px',
    maxWidth: '1248px',
    margin: '0 auto',
    left: '50%',
    transform: 'translate(-50%, 0)',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '4rem',
  },
  eyebrowLogo: {
    padding: '4rem 0',
    minHeight: '85px',
    minWidth: '85px',
    height: 'auto',
    width: 'auto',
  },
  separator: {
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  headline: {
    color: (props) =>
      props.color ? props.color.color : theme.palette.secondary.main,
    textAlign: 'center',
    padding: '1rem',
    display: 'block',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 3rem 3rem',
    },
  },
  description: {
    color: (props) =>
      (props.gradient && theme.palette.text.white) ||
      theme.palette.text.tertiary,
    textAlign: 'center',
    padding: '0px 1rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 1rem 2rem',
    },
    display: 'block',
    ...makeResponsiveFontSizes(24, 15),
  },
  button: {
    textAlign: 'center',
    paddingBottom: '3rem',
    '& button': {
      height: '30px',
      padding: '5px 15px',
      [theme.breakpoints.up('sm')]: {
        height: '39px',
        padding: '12px 18px',
      },
    },
    '& button .material-icons': {
      fontSize: '16px',
      padding: '0px 5px',
      [theme.breakpoints.up('sm')]: {
        padding: '0px 12px',
      },
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const HeadlineDescriptionWithImagesAndCTA = (props) => {
  const {
    button,
    description,
    eyebrowIcon,
    headline,
    separator,
    isFullWidth,
    images,
    shouldRenderH1,
  } = props.blok

  const gradient = !!props.gradient || true
  const classes = useStyles({ ...props.blok, gradient })

  return (
    <SbEditable content={props.blok}>
      <PageContainer parentWidth={isFullWidth}>
        <Box className={classes.outer}>
          <Box className={classes.topImage} />
          <ContentContainer>
            <Box className={classes.inner}>
              <Box className={classes.eyebrowLogo}>
                {renderBloks(eyebrowIcon)}
              </Box>
              <H3
                component={shouldRenderH1 ? 'h1' : 'h3'}
                className={classes.headline}
              >
                {headline}
              </H3>
              <Text className={classes.description}>{description}</Text>
              <Box className={classes.separator}>{renderBloks(separator)}</Box>
              <Box className={classes.imagesContainer}>
                {renderBloks(images)}
              </Box>
              <Typography display="block" className={classes.button}>
                {renderBloks(button)}
              </Typography>
            </Box>
          </ContentContainer>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default HeadlineDescriptionWithImagesAndCTA
